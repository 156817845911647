// ** @jsx jsx *
import React, { useState, useEffect, useContext } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Header from "../components/ui/Header"
import Layout from "../components/Layout"
import { CartContext, DataGetContext } from "../components/context/Context"
import ProductList from "../components/ProductList"
import SendButton from "../components/ui/SendButton"
import { jsx, Text, Alert } from "theme-ui"
import Form from "../components/Form"
import { IoIosShareAlt } from "react-icons/io"
import { BsCheckAll } from "react-icons/bs"
import { FaRegTrashAlt } from "react-icons/fa"
import { GrDocumentPdf } from "react-icons/gr"
import CartEmpty from "../components/ui/CartEmpty.jsx"
import ReactPixel from "../lib/react-facebook-pixel"
import Link from "../components/ui/LinkDataGet.jsx"
import saveCart from "../lib/saveCart"
const Carrito = ({ data }) => {
  const { cart, setCart } = useContext(CartContext)
  const { dataGet, setDataGet } = useContext(DataGetContext)
  const [copied, setCopied] = useState(false)
  const linkCartShare =
    typeof window !== "undefined" &&
    `${window.location.href}${dataGet.search ? "&" : "?"}cart=${dataGet.cart}`

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        data.sanityIntegration &&
        data.sanityIntegration.facebookPixel &&
        data.sanityIntegration.facebookPixel &&
        data.sanityIntegration.facebookPixel.pixelId
      ) {
        ReactPixel.trackCustom("ViewCart", {
          whatshop_content_type: "cart",

          value: (() => {
            let _totalPrice = 0
            if (cart && cart.length > 0) {
              cart.forEach(({ totalPrice }) => {
                _totalPrice += totalPrice
              })
            }
            return _totalPrice
          })(),
          quantity: (() => {
            let _totalCount = 0
            if (cart && cart.length > 0) {
              cart.forEach(({ totalCount }) => {
                _totalCount += totalCount
              })
            }
            return _totalCount
          })(),
          currency: data.sanityIntegration.facebookPixel.currency,
        })
      }
    }
  }, [])
  return (
    <Layout>
      <Header prevPage="/" />
      <br />
      {cart && cart.length > 0 ? (
        <>
          <div
            sx={{
              variant: "div.flexColumn",
              boxShadow: "pressedWhatsapp",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <div
              sx={{
                variant: "div.flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div
                sx={{ width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => {
                  setCart([])
                  if (typeof window !== "undefined") {
                    window.localStorage.removeItem("lastOrder")
                  }
                }}
              >
                <div
                  sx={{
                    variant: "div.flex",
                    justifyContent: "center",
                    padding: 2,
                    borderRadius: "50%",
                    width: 18,
                    height: 18,
                    boxShadow: "flatSmall",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "pressedSmall",
                    },
                  }}
                >
                  <FaRegTrashAlt />
                </div>
              </div>
              <div sx={{ variant: "div.flexColumn" }}>
                {dataGet.cart &&
                  (!copied ? (
                    <CopyToClipboard
                      text={linkCartShare}
                      onCopy={() => setCopied(true)}
                    >
                      <div
                        sx={{
                          variant: "div.flex",
                          color: "primary",
                          fontFamily: "heading",
                        }}
                        target="_blank"
                      >
                        <IoIosShareAlt sx={{ fontSize: 3 }} />
                        Compartir carrito
                      </div>
                    </CopyToClipboard>
                  ) : (
                    <div
                      sx={{
                        color: "primary",
                        fontFamily: "heading",
                        backgroundColor: "transparent",
                        variant: "div.flex",
                      }}
                    >
                      <BsCheckAll sx={{ fontSize: 3, marginRight: 1 }} />
                      Enlace copiado
                    </div>
                  ))}
                <Text variant="caption">Resumen de pedido</Text>
              </div>

              <Link
                sx={{ width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}
                to="/carrito-pdf"
              >
                <div
                  sx={{
                    variant: "div.flex",
                    justifyContent: "center",
                    padding: 2,
                    borderRadius: "50%",
                    width: 18,
                    height: 18,
                    boxShadow: "flatSmall",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "pressedSmall",
                    },
                    color: "#fffffff",
                    fill: "#ffffff",
                    "path":{
                      stroke: "#ffffff"
                    }
                  }}
                >
                  <GrDocumentPdf color="#ffffff" fill="#ffffff" style={{
                    color: "#ffffff",
                    fill: "#ffffff",
                  }}/>
                </div>
              </Link>
            </div>
            <ProductList
              products={cart}
              money={data.sanitySetting.money}
              heightProductList="40vh"
              prevPage={"/carrito"}
            />
          </div>
          {/* <button onClick={saveCart} style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            width: "100%",
            height: "50px",
            border: "none",
            borderRadius: "0 0 16px 16px",
            boxShadow: "flatWhatsapp",
            cursor: "pointer",
          }}>Save Cart</button> */}
          <Form linkCartShare={linkCartShare} />
        </>
      ) : (
        <CartEmpty />
      )}
    </Layout>
  )
}

export default Carrito
export const query = graphql`
  query DataCart {
    sanitySetting {
      money
    }
    sanityIntegration {
      facebookPixel {
        pixelId
        currency
      }
    }
  }
`
